import * as React from "react"
import { Link, graphql } from 'gatsby';

import * as galleryStyles from '../styles/galleries.module.css'

import Layout from '../components/layout';
import Seo from '../components/seo';

const Galleries = ({ data }) => {
    return (
        <Layout>
            <Seo
              title="Andre's Galleries"
              description="Listing of my galleries and work"
            />
            {data.allDirectory.edges.map( ({ node }) => (
                <section className={galleryStyles.section} key={node.id}>
                    <h2 className={galleryStyles.galleryHeading}>{node.name}</h2>
                    <Link className={galleryStyles.galleryBtn} to={node.name}>
                        View
                    </Link>
                </section>
            ))}

            <div className={galleryStyles.moreWork}>
              <h2>More Work Coming Soon.</h2>
              <p>Stay tuned. Im currently working on some big projects!</p>
            </div>
        </Layout>
    )
}

export default Galleries;

export const pageQuery = graphql`
query MyQuery {
    allDirectory(
      filter: {sourceInstanceName: {eq: "galleries"}, relativeDirectory: {eq: ""}}
    ) {
      edges {
        node {
          id
          name
          absolutePath
        }
      }
    }
  }
`